import logo from './logo.svg';
import './App.css';
import { Outlet } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import Peer from 'peerjs';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setOnlineUser, setPeer, setSocketConnection } from './redux/userSlice';
import CallModal from './components/CallModal';
import SocketClient from './SocketClient';
import io from 'socket.io-client'

function App() {

  const dispatch = useDispatch();
  const user = useSelector(state => state.user)

  /***socket connection */
  // useEffect(()=>{
  //   const socketConnection = io(process.env.REACT_APP_BACKEND_URL,{
  //     auth : {
  //       token : localStorage.getItem('token')
  //     },
  //   })

  //   socketConnection.on('onlineUser',(data)=>{
  //     console.log(data)
  //     dispatch(setOnlineUser(data))
  //   })

  //   dispatch(setSocketConnection(socketConnection))

  //   return ()=>{
  //     socketConnection.disconnect()
  //   }
  // },[])



  useEffect(() => {
    const newPeer = new Peer(undefined, {
      // host: '/', port: '3001'
      path: '/',
      secure: true,
      // port: 9000,
      // debug: true,
      // path: '/peerjs',
      // config: {'iceServers': [
      // { url: 'stun:stun.l.google.com:19302' },
      // ]} 

      config: {iceServers: [
        { url: 'stun:stun1.l.google.com:19302' },
        { url: 'stun:stun2.l.google.com:19302' },
      //   {
      //     url: 'turn:numb.viagenie.ca',
      //     credential: 'muazkh',
      //     username: 'webrtc@live.com'
      // },
      ]}
    });

    console.log("newPeer RRRRRRRRRR", newPeer)
    
    dispatch(setPeer(newPeer))
  }, [dispatch]);

  return (
   <> 
      {user && <SocketClient/>}
      {user?.call && <CallModal />}
      <Toaster/>
       <main >
        <Outlet/>
       </main>
   </>
  );
}

export default App;
